<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="绑定手机号"
                z-index="99"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="div">
            <p class="bind_iphone">
                <img src="../../assets/images/bind_iphone_img.png" alt="" class="bind_iphone_img">
            </p>
            <div class="bind_iphone_input">
                <img src="../../assets/images/bind_iphone_icon1.png" alt="" class="bind_iphone_icon1">
                <input type="number" v-model="phone" placeholder="请输入您的手机号码" class="book_input" maxlength="11">
            </div>
            <div class="bind_iphone_input">
                <img src="../../assets/images/bind_iphone_icon2.png" alt="" class="bind_iphone_icon1">
                <input type="text" v-model="code" placeholder="请输入验证码" class="book_input">
                <p class="code" :class="!code_show?'code_active':''" @click="codeClick">
                    {{yzmText}}
                </p>
            </div>
            <div class="bind_iphone_input">
                <img src="../../assets/images/bind_iphone_icon3.png" alt="" class="bind_iphone_icon1">
                <input type="password" v-model="password" placeholder="请设置至少8位数密码" class="book_input">
            </div>
            <p class="book-button" v-if="book_active" @click="login">
                确定
            </p>
            <p class="book-button book_active" v-else>
                确定
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReplacePhone",
        data() {
            return {
                phone: '',
                code: '',
                password: '',
                openid: '',
                yzmText: "获取验证码",
                code_show: false,
                book_active: false,
                time: 60,
            }
        },
        watch: {
            phone(e) {
                if (e.length == 11) {
                    if (this.time == 60) {
                        this.code_show = true
                    }
                } else {
                    this.code_show = false
                }
            },
            code(e) {
                if (e && this.phone.length == 11 && this.password.length >= 8) {
                    this.book_active = true
                } else {
                    this.book_active = false
                }
            },
            password(e) {
                if (e.length >= 8 && this.code && this.phone.length == 11) {
                    this.book_active = true
                } else {
                    this.book_active = false
                }
            }

        },
        created() {
            this.openid = this.$route.query.openid
        },
        methods: {
            login() {
                this.$axios.post('/api/change_phone', {
                    phone: this.phone,
                    code: this.code,
                    password: this.password,
                    // openid: this.openid,
                })
                    .then(res => {
                        // localStorage.setItem("token", res.data.data.access_token)
                        // localStorage.setItem("openid", this.openid)
                        this.$toast.success('绑定成功');
                        this.$router.replace({
                            path: '/binding'
                        })
                    })
                    .catch(err => {
                        this.$toast(err.response.data.message)
                    })
            },
            codeClick() {
                if (!this.code_show) return
                 this.$ajax({
                    method: 'POST',
                    url: '/api/common/sms_send',
                    dataType:'json',
                    data: {'mobile':this.phone,'type':'change_phone'}
                })
                    .then(res => {

                let timer = setInterval(() => {
                    if (this.time == 0) {
                        clearInterval(timer);
                        this.yzmText = '获取验证码';
                        this.code_show = true;
                        this.time = 60
                    } else {
                        this.yzmText = this.time + '秒后再次获取';
                        this.code_show = false;
                        this.time--;
                    }
                }, 1000)
                    })
                    .catch(err => {
                        this.$toast('发送失败');
                    })
              
            }
        }
    }
</script>

<style scoped>
    .book_active {
        background: rgba(77, 119, 253, .5) !important;
    }

    .book-button {
        height: 88px;
        background: rgba(77, 119, 253, 1);
        color: #fff;
        border-radius: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 84px;
    }

    .code_active {
        opacity: .5;
    }

    .code {
        height: 88px;
        border-radius: 0 44px 44px 0;
        background: #4D77FD;

        color: #fff;
        font-size: 28px;
        display: flex;
        width: 600px;
        align-items: center;
        justify-content: center;
    }

    .book_input::-webkit-input-placeholder {
        color: #BFC2CC;
    }

    .book_input {
        background: none;
        font-size: 30px;
        color: #303133;
        width: 100%;
        padding-right: 40px;
    }

    .bind_iphone_icon1 {
        width: 22px;
        height: 28px;
        margin-right: 10px;
        margin-left: 40px;
    }

    .bind_iphone_input {
        height: 88px;
        background: rgba(242, 245, 248, 1);
        border-radius: 44px;
        display: flex;
        align-items: center;

        margin-top: 50px;

    }

    .bind_iphone {
        text-align: center;
    }

    .bind_iphone_img {
        width: 156px;
        height: 156px;
        margin-top: 50px;
    }
</style>