import { render, staticRenderFns } from "./ReplacePhone.vue?vue&type=template&id=ed6cdecc&scoped=true"
import script from "./ReplacePhone.vue?vue&type=script&lang=js"
export * from "./ReplacePhone.vue?vue&type=script&lang=js"
import style0 from "./ReplacePhone.vue?vue&type=style&index=0&id=ed6cdecc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed6cdecc",
  null
  
)

export default component.exports